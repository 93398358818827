<!-- 6 合作企業 @lynu369 -->
<template>
  <div
    class="hidden md:block relative w-[full] overflow-hidden z-20 box-border mt-[200px]"
  >
    <div class="flex items-center justify-center w-full w-[70%] left-[50%]">
      <object
        data="/home/icon-ve35-2.svg"
        type="image/svg+xml"
        class="flex-none mx-4 mt-14"
      ></object>
      <div
        class="font-Mantou text-[#43473E] text-[36px] text-[400] flex-none text-center"
      >
        合作企業
      </div>
      <object
        data="/home/icon-ve34-1.svg"
        type="image/svg+xml"
        class="flex-none mx-4 mb-14"
      ></object>
    </div>
    <div class="flex flex-col sm:flex-row gap-x-7 justify-center">
    <!-- <div
      class="grid grid-cols-2 gap-y-3 gap-x-0 items-center justify-items-center absolute top-[7%] left-[50%] translate-x-[-50%] w-[40%]"
    > -->
      <a href="https://www.hccg.gov.tw/ch/index.jsp"
        ><div class="flex justify-center items-center">
          <object
            data="/home/logo-HCG.svg"
            type="image/svg+xml"
            class="h-20"
          ></object></div
      ></a>
      <a href="https://www.facebook.com/nxpsemitaiwan"
        ><div class="flex justify-center items-center">
          <object
            data="/home/logo-NXP.png"
            type="image/png"
            class="h-20"
          ></object></div
      ></a>
    </div>
    <div class="flex flex-col sm:flex-row gap-x-24 justify-center mt-20">
      <a href="https://techblog.lycorp.co.jp/zh-hant/">
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-LINE.svg"
            type="image/svg+xml"
            class="h-10"
          ></object>
        </div>
      </a>
      <a href="https://goo.gle/TWCareers"
        ><div class="flex justify-center items-center">
          <object
            data="/home/logo-Google.png"
            type="image/png"
            class="h-14"
          ></object></div
      ></a>
    </div>
    <div class="flex flex-col sm:flex-row ml-6 gap-x-[80px] justify-center mt-16">
      <a href="https://www.tsmc.com/static/chinese/careers/it_career/index.html"
        ><div class="flex justify-center items-center mt-4">
          <object
            data="/home/logo-TSMC.png"
            type="image/png"
            class="h-20"
          ></object></div
      ></a>
      <a
        href="https://jobs.jobvite.com/logitech-internal/search?r=Taiwan&c=&s=&q="
        ><div class="flex justify-center items-center">
          <object
            data="/home/logo-Logitech.png"
            type="image/png"
            class="h-28"
          ></object></div
      ></a>
    </div>
    <div class="flex flex-col sm:flex-row gap-x-8 justify-center mt-16">
      <a href="https://www.cht.com.tw/zh-tw/home/cht"
        class="flex justify-center items-center col-span-2"
      > 
        <object
            data="/home/logo-CHT.svg"
            type="image/svg+xml"
            class="h-14"
          ></object></a>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[24px] text-[400] text-center mt-16"
    >
      特別合作企業
    </div>
    <div
      class="grid grid-cols-1 gap-y-3 gap-x-0 items-center justify-items-center mt-16"
    >
      <object
        data="/home/logo-WT.png"
        type="image/png"
        class="h-24"
      ></object>
    </div>

    <div
      class="flex items-center justify-center w-full"
    >
      <object
        data="/home/icon-ve34-2.svg"
        type="image/svg+xml"
        class="flex-none mx-4 mb-14"
      ></object>
      <div
        class="font-Mantou text-[#43473E] text-[36px] text-[400] flex-none text-center"
      >
        網路贊助
      </div>
      <object
        data="/home/icon-ve36-2.svg"
        type="image/svg+xml"
        class="flex-none mx-4 mt-14"
      ></object>
    </div>
    <div
      class="grid grid-cols-1 gap-x-0 items-center justify-items-center"
    >
      <object
        data="/home/logo-CHT.svg"
        type="image/svg+xml"
        class="h-14"
      ></object></div
    >

    <div
      class="flex items-center justify-center w-full mt-20"
    >
      <object
        data="/home/icon-ve84.svg"
        type="image/svg+xml"
        class="flex-none mx-4 mt-14"
      ></object>
      <div
        class="font-Mantou text-[#43473E] text-[36px] text-[400] flex-none text-center"
      >
        贊助企業
      </div>
      <object
        data="/home/icon-ve83.svg"
        type="image/svg+xml"
        class="flex-none mx-4 mb-14"
      ></object>
    </div>
    <object
      data="/home/icon-co16.svg"
      type="image/svg+xml"
      class="sponsor-co16 absolute top-[78%] left-[72%]"
    ></object>
    <object
      data="/home/icon-ve36-1.svg"
      type="image/svg+xml"
      class="absolute top-[40%] left-[82%]"
    ></object>
    <object
      data="/home/icon-ve35-1.svg"
      type="image/svg+xml"
      class="absolute top-[28%] left-[2%]"
    ></object>
    <object
      data="/home/icon-ve37-1.svg"
      type="image/svg+xml"
      class="absolute top-[50%] left-[20%]"
    ></object>
    <object
      data="/home/icon-co11.svg"
      type="image/svg+xml"
      class="sponsor-co11 absolute top-[52%] md:left-[-5%] lg:left-[6%]"
    ></object>
    <object
      data="/home/icon-ve41.svg"
      type="image/svg+xml"
      class="absolute top-[76%] left-[7%]"
    ></object>
    <object
      data="/home/icon-co14.svg"
      type="image/svg+xml"
      class="sponsor-co14 absolute top-[85%] left-[70%]"
    ></object>
    <div
      class="grid grid-cols-1 gap-y-14 items-center justify-items-center"
    >
      <div
        class="font-Mantou text-[#43473E] text-[24px] font-medium"
      >
        Diamond
      </div>
      <div class="flex flex-col sm:flex-row gap-x-24 items-center justify-center">
        <object
          data="/home/logo-Shuttle.svg"
          type="image/svg+xml"
          class="h-14"
        ></object>
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-Jumbo.png"
              type="image/png"
              class="h-20"
            ></object>
          </div>
        </div>
    </div>
    <div
      class="grid grid-cols-1 gap-y-14 mt-16 items-center justify-items-center"
    >
      <div class="font-Mantou text-[#43473E] text-[24px] font-medium">
        Platinum
      </div>

      <div class="flex flex-col sm:flex-row gap-2 items-center justify-center">
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-Cathay.svg"
            type="image/svg+xml"
            class="h-20"
          ></object>
        </div>
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-CloudMosa.png"
            type="image/png"
            class="h-20"
          ></object>
        </div>
      </div>
    </div>

    <div
      class="grid grid-cols-1 gap-y-14 mt-16 items-center justify-items-center"
    >
      <div class="font-Mantou text-[#43473E] text-[24px] font-medium">
        Gold
      </div>
      <div class="flex flex-col sm:flex-row gap-x-20 items-center justify-center">
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-Jkopay.svg"
            type="image/svg+xml"
            class="h-14"
          ></object>
        </div>
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-TM.svg"
            type="image/svg+xml"
            class="h-14"
          ></object>
        </div>
      </div>
    </div>


  </div>
  <div
    class="hidden md:block relative w-[full] overflow-hidden z-20 box-border"
  >
  <div
    class="grid grid-cols-1 gap-y-14 gap-x-2 mt-16 items-center justify-items-center"
  >
    <div class="font-Mantou text-[#43473E] text-[24px] font-medium">
      Silver
    </div>
    <div class="flex flex-col sm:flex-row gap-x-8 justify-center">
      <div class="flex justify-center items-center">
        <object
          data="/home/logo-PXI.png"
          type="image/png"
          class="h-10"
        ></object>
      </div>
      <div class="flex justify-center items-center">
        <object
          data="/home/logo-CyCraft.svg"
          type="image/svg+xml"
          class="h-20"
        ></object>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row gap-x-16 justify-center">
      <div class="flex justify-center items-center">
        <object
          data="/home/logo-Phison.jpg"
          type="image/jpg"
          class="h-10"
        ></object>
      </div>
      <div class="flex justify-center items-center">
        <object
          data="/home/logo-Synopsys.png"
          type="image/png"
          class="h-10"
        ></object>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row gap-x-16 justify-center">
      <div class="flex justify-center items-center">
        <object
          data="/home/logo-Ganzin.svg"
          type="image/svg+xml"
          class="h-10"
        ></object>
      </div>
      <div class="flex justify-center items-center">
        <object
          data="/home/logo-BigData.svg"
          type="image/svg+xml"
          class="h-10"
        ></object>
      </div>
    </div>
  </div>

      <div
        class="grid grid-cols-1 gap-y-14 mt-16 items-center justify-items-center"
      >
        <div class="font-Mantou text-[#43473E] text-[24px] font-medium mb-4">
          Bronze
        </div>
        <div class="flex flex-col sm:flex-row gap-x-8 justify-center">
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-CTBC.png"
              type="image/png"
              class="h-20"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-104.svg"
              type="image/svg+xml"
              class="h-14"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-wavenet.svg"
              type="image/svg+xml"
              class="h-14"
            ></object>
          </div>
        </div>
      </div>
      
      <div
      class="flex items-center justify-center w-full mt-24"
      >
        <object
          data="/home/icon-ve85.svg"
          type="image/svg+xml"
          class="flex-none mx-4 mb-14"
        ></object>
        <div
          class="font-Mantou text-[#43473E] text-[36px] text-[400] flex-none text-center"
        >
          特殊贊助
        </div>
        <object
          data="/home/icon-ve86.svg"
          type="image/svg+xml"
          class="flex-none mx-4 mt-14"
        ></object>
      </div>
      <div
        class="grid grid-cols-1 gap-y-10 items-center justify-items-center"
      >
        <div class="font-Mantou text-[#43473E] text-[24px] font-medium">
          Platinum
        </div>
        <div class="grid grid-cols-1 gap-x-4 gap-y-8 items-center justify-items-center">
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-activity.svg"
              type="image/svg+xml"
              class="h-14 scale-110"
            ></object>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-1 gap-y-10 mt-16 items-center justify-items-center"
      >
        <div class="font-Mantou text-[#43473E] text-[24px] font-medium">
          Silver
        </div>
        <div class="grid grid-cols-1 gap-x-4 gap-y-8 items-center justify-items-center">
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-ceomate.svg"
              type="image/svg+xml"
            ></object>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-1 gap-y-10 mt-16 items-center justify-items-center"
      >
        <div class="font-Mantou text-[#43473E] text-[24px] font-medium mb-4">
          Bronze
        </div>
        <div class="grid grid-cols-2 gap-x-20 gap-y-8 items-center justify-items-center">
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-mille.svg"
              type="image/svg+xml"
              class="h-14"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-domino.svg"
              type="image/svg+xml"
              class="h-14"
            ></object>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-1 mt-16 gap-y-10 items-center justify-items-center"
      >
        <div class="font-Mantou text-[#43473E] text-[24px] font-medium">
          前期活動A級
        </div>
        <div class="grid grid-cols-1 gap-x-4 gap-y-8 items-center justify-items-center">
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-AlphaCamp.svg"
              type="image/svg+xml"
              class="h-20"
            ></object>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-1 mt-16 gap-y-10 items-center justify-items-center"
      >
        <div class="font-Mantou text-[#43473E] text-[24px] font-medium">
          前期活動B級
        </div>
        <div class="flex flex-col sm:flex-row gap-x-16 justify-center">
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-SnowFactory.svg"
              type="image/svg+xml"
              class="h-20"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-MaybeHomestay.svg"
              type="image/svg+xml"
              class="h-20"
            ></object>
          </div>
          </div>
          <div class="flex flex-col sm:flex-row gap-x-8 justify-center mt-8">
            <div class="flex justify-center items-center col-span-2">
              <object
                data="/home/logo-Tlife.svg"
                type="image/svg+xml"
                class="h-20"
              ></object>
            </div>
          </div>
      </div>
    <object
      data="/home/icon-mo05.svg"
      type="image/svg+xml"
      class="sponsor-mo5 absolute top-[50%] left-[10%] z-10"
    ></object>
    <object
      data="/home/icon-ve81.svg"
      type="image/svg+xml"
      class="absolute top-[13%] left-[72%]"
    ></object>
    <object
      data="/home/icon-co13.svg"
      type="image/svg+xml"
      class="sponsor-co13 absolute top-[54%] left-[68%]"
    ></object>
    <object
      data="/home/icon-ve82.svg"
      type="image/svg+xml"
      class="absolute top-[26%] left-[80%]"
    ></object>
    <object
      data="/home/icon-ve43.svg"
      type="image/svg+xml"
      class="absolute top-[42%] left-[20%]"
    ></object>
    <object
      data="/home/icon-ve45.svg"
      type="image/svg+xml"
      class="absolute top-[85%] left-[83%]"
    ></object>
    <object
      data="/home/icon-ve44.svg"
      type="image/svg+xml"
      class="absolute top-[81%] left-[0%]"
    ></object>
  </div>

  <div
    class="hidden md:block relative w-[full] overflow-hidden z-20 box-border"
  >
    <div
        class="grid grid-cols-1 mt-16 gap-y-10 items-center justify-items-center"
      >
        <div class="font-Mantou text-[#43473E] text-[24px] font-medium">
          前期活動C級
        </div>
        <div class="grid grid-cols-2 gap-x-11 gap-y-8 items-center justify-items-center">
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-three.svg"
              type="image/svg+xml"
              class="h-20"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-eatwithgo.svg"
              type="image/svg+xml"
              class="h-20"
            ></object>
          </div>
        </div>
      </div>
      <div
          class="flex items-center justify-center w-full"
        >
            <object
              data="/home/icon-ve84.svg"
              type="image/svg+xml"
              class="flex-none mx-4 mt-14"
            ></object>
            <div
              class="font-Mantou text-[#43473E] text-[36px] text-[400] flex-none text-center"
            >
              媒體合作
            </div>
            <object
              data="/home/icon-ve83.svg"
              type="image/svg+xml"
              class="flex-none mx-4 mb-14"
            ></object>
        </div>
      <div
        class="grid grid-cols-1 gap-y-12 gap-x-2 items-center justify-items-center"
      >
        <div class="font-Mantou text-[#43473E] text-[24px] font-medium mb-4">
          Diamond
        </div>
        <div class="grid grid-cols-2 gap-x-20 gap-y-8 items-center justify-items-center">
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-DailyView.svg"
              type="image/svg+xml"
              class="h-16"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-TaiwanNews.svg"
              type="image/svg+xml"
              class="h-16"
            ></object>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-1 mt-16 gap-y-10 gap-x-2 items-center justify-items-center"
      >
        <div class="font-Mantou text-[#43473E] text-[24px] font-medium mb-4">
          Platinum
        </div>
        <div class="grid grid-cols-1 gap-x-4 gap-y-8 items-center justify-items-center">
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-ETtoday.svg"
              type="image/svg+xml"
              class="h-14"
            ></object>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-1 mt-16 gap-y-10 gap-x-2 items-center justify-items-center"
      >
        <div class="font-Mantou text-[#43473E] text-[24px] font-medium mb-4">
          共同發聲
        </div>
        <div class="flex flex-col sm:flex-row gap-x-8 justify-center">
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-TiEA.svg"
              type="image/svg+xml"
              class="h-14"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-TCF.svg"
              type="image/svg+xml"
              class="h-14"
            ></object>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-center w-full"
        >
        <object
          data="/home/icon-ve85.svg"
          type="image/svg+xml"
          class="flex-none mx-4 mb-14"
        ></object>
        <div
          class="font-Mantou text-[#43473E] text-[36px] text-[400] flex-none text-center"
        >
          協辦單位
        </div>
        <object
          data="/home/icon-ve86.svg"
          type="image/svg+xml"
          class="flex-none mx-4 mt-14"
        ></object>
      </div>
      <div class="flex justify-center w-full mt-20 px-[12vw] flex-wrap gap-x-[10vw] gap-y-12">
        <object
          data="/home/logo-ITSA.svg"
          type="image/svg+xml"
          class="h-16"
        ></object>
        <div class="flex justify-center items-center font-Inter text-[24px] font-normal text-center">國立陽明交通大學資訊工程學系</div>
        <object
          data="/home/logo-NCTU.svg"
          type="image/svg+xml"
          class="h-16"
        ></object>
      </div>
      <div
          class="flex items-center justify-center w-full my-20"
        >
            <object
              data="/home/icon-ve84.svg"
              type="image/svg+xml"
              class="flex-none mx-4 mt-14"
            ></object>
            <div
              class="font-Mantou text-[#43473E] text-[36px] text-[400] flex-none text-center"
            >
              特別感謝
            </div>
            <object
              data="/home/icon-ve83.svg"
              type="image/svg+xml"
              class="flex-none mx-4 mb-14"
            ></object>
        </div>
        <div class="flex font-Inter px-[12vw] gap-x-8 gap-y-6 text-[24px] font-normal text-center justify-center flex-wrap mb-[100px]">
          <div>國立陽明交通大學產學運籌中心</div>
          <div>國立陽明交通大學電機工程學系</div>
          <div>國立清華大學資訊工程學系</div>
          <div>國立清華大學電機資訊學院學士班</div>
          <div>國立清華大學清華學院學士班系學會</div>
          <div>國立清華大學 26 級經濟系學會</div>
          <div>Colde Garage</div>
        </div>
    <object
      data="/home/icon-co13.svg"
      type="image/svg+xml"
      class="sponsor-co13 absolute top-[0%] left-[5%]"
    ></object>
    <object
      data="/home/icon-co13.svg"
      type="image/svg+xml"
      class="sponsor-co13 absolute top-[12%] left-[72%] z-10"
    ></object>
    <object
      data="/home/icon-co08.svg"
      type="image/svg+xml"
      class="sponsor-co8 absolute top-[35%] left-[5%]"
    ></object>
    <object
      data="/home/icon-ve129.svg"
      type="image/svg+xml"
      class="absolute top-[45%] left-[30%] -z-10"
    ></object>
    <object
      data="/home/icon-ve130.svg"
      type="image/svg+xml"
      class="absolute top-[75%] left-[10%] -z-10"
    ></object>
    <object
      data="/home/icon-ve131.svg"
      type="image/svg+xml"
      class="absolute top-[81%] left-[60%] -z-10"
    ></object>
  </div>

<!-- Mobile -->
  <div
    class="block md:hidden relative h-[1700px] w-[full] overflow-hidden z-20 box-border"
  >
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[0%] left-[50%] translate-x-[-50%]"
    >
      合作企業
    </div>
    
    <div
      class="absolute top-[2%] left-[50%] translate-x-[-50%] w-[70%] grid grid-cols-2 gap-y-8 gap-x-1 items-center justify-items-center"
    >
    <a href="https://www.hccg.gov.tw/ch/index.jsp">
      <div class="flex justify-center items-center">
        <object
          data="/home/logo-HCG.svg"
          type="image/svg+xml"
          class="h-10"
        ></object></div
      >
    </a>
      <a href="https://www.facebook.com/nxpsemitaiwan"
        ><div class="flex justify-center items-center">
          <object
            data="/home/logo-NXP.png"
            type="image/png"
            class="h-10"
          ></object></div
      ></a>
      <a href="https://techblog.lycorp.co.jp/zh-hant/">
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-LINE.svg"
            type="image/svg+xml"
            class="h-6"
          ></object>
        </div>
      </a>
      <a href="https://goo.gle/TWCareers"
        ><div class="flex justify-center items-center">
          <object
            data="/home/logo-Google.png"
            type="image/png"
            class="h-6"
          ></object></div
      ></a>
      <a href="https://www.tsmc.com/static/chinese/careers/it_career/index.html"
        ><div class="flex justify-center items-center">
          <object
            data="/home/logo-TSMC.png"
            type="image/png"
            class="h-10"
          ></object></div
      ></a>
      <a
        href="https://jobs.jobvite.com/logitech-internal/search?r=Taiwan&c=&s=&q="
        ><div class="flex justify-center items-center">
          <object
            data="/home/logo-Logitech.png"
            type="image/png"
            class="h-10"
          ></object></div
      ></a>
      <a href="https://www.cht.com.tw/zh-tw/home/cht" class="col-span-2"
        ><div class="flex justify-center items-center">
          <object
            data="/home/logo-CHT.svg"
            type="image/svg+xml"
            class="h-8"
          ></object></div
      ></a>
    </div>

    <div
      class="font-Mantou text-[#43473E] text-[10px] text-[400] absolute top-[18%] left-[50%] translate-x-[-50%]"
    >
      特別合作企業
    </div>
    <div
      class="grid grid-cols-1 gap-y-3 gap-x-0 items-center justify-items-center absolute top-[19.5%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <object
        data="/home/logo-WT.png"
        type="image/png"
        class="h-14"
      ></object>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[24.5%] left-[50%] translate-x-[-50%]"
    >
      網路贊助
    </div>
    <div
      class="grid grid-cols-1 gap-y-3 gap-x-0 items-center justify-items-center absolute top-[27.3%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <object
        data="/home/logo-CHT.svg"
        type="image/svg+xml"
        class="h-8"
      ></object>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[31.3%] left-[50%] translate-x-[-50%]"
    >
      贊助企業
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[33%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        Diamond
      </div>
      <div class="grid grid-cols-1 gap-y-3 items-center justify-items-center">
        <div class="flex justify-center items-center">
      <object
        data="/home/logo-Shuttle.svg"
        type="image/svg+xml"
        class="h-10 scale-90"
      ></object>
    </div>
      <div class="flex justify-center items-center">
          <object
            data="/home/logo-Jumbo.png"
            type="image/png"
            class="h-10"
          ></object>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[41%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        Platinum
      </div>
      <div class="grid grid-cols-1 gap-y-3 items-center justify-items-center">
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-Cathay.svg"
            type="image/svg+xml"
            class="h-10"
          ></object>
        </div>
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-CloudMosa.png"
            type="image/png"
            class="h-10"
          ></object>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[50.5%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        Gold
      </div>
      <div class="grid grid-cols-1 gap-y-6 items-center justify-items-center">
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-Jkopay.svg"
            type="image/svg+xml"
            class="h-8"
          ></object>
        </div>
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-TM.svg"
            type="image/svg+xml"
            class="h-8 scale-75"
          ></object>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[59.5%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        Silver
      </div>
      <div class="grid grid-cols-1 gap-y-5 items-center justify-items-center">
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-PXI.png"
              type="image/png"
              class="h-6 scale-95"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-CyCraft.svg"
              type="image/svg+xml"
              class="h-14"
            ></object>
          </div>
          <div class="grid grid-cols-1 gap-y-8 items-center justify-items-center">
            <div class="flex justify-center items-center">
              <object
                data="/home/logo-Phison.jpg"
                type="image/jpg"
                class="h-6 scale-[.8]"
              ></object>
            </div>
            <div class="flex justify-center items-center">
              <object
                data="/home/logo-Synopsys.png"
                type="image/png"
                class="h-6 scale-90"
              ></object>
            </div>
            <div class="flex justify-center items-center">
              <object
                data="/home/logo-Ganzin.svg"
                type="image/svg+xml"
                class="h-6 scale-[.8]"
              ></object>
            </div>
            <div class="flex justify-center items-center">
              <object
                data="/home/logo-BigData.svg"
                type="image/svg+xml"
                class="h-6"
              ></object>
            </div>
          </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[80.7%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        Bronze
      </div>
      <div class="grid grid-cols-1 gap-y-5 items-center justify-items-center">
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-CTBC.png"
            type="image/png"
            class="h-10 scale-[1.2]"
          ></object>
        </div>
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-104.svg"
            type="image/svg+xml"
            class="h-8 scale-90"
          ></object>
        </div>
        <div class="flex justify-center items-center">
          <object
            data="/home/logo-wavenet.svg"
            type="image/svg+xml"
            class="h-8 scale-[.8]"
          ></object>
        </div>
      </div>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[93%] left-[50%] translate-x-[-50%]"
    >
      特殊贊助
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[94.8%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        Platinum
      </div>
      <div class="grid grid-cols-1 gap-y-2 items-center justify-items-center">
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-activity.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
      </div>
    </div>

    <object
      data="/home/icon-ve108.svg"
      type="image/svg+xml"
      class="absolute top-[34%] left-[60%]"
    ></object>
    <object
      data="/home/icon-ve97.svg"
      type="image/svg+xml"
      class="absolute top-[50%] left-[87%]"
    ></object>


  </div>
  <div
    class="block md:hidden relative h-[1600px] w-[full] overflow-hidden z-20 box-border"
  >
  <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[0%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        Silver
      </div>
      <div class="grid grid-cols-1 gap-y-2 items-center justify-items-center">
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-ceomate.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
      </div>
    </div>
  <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[6%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        Bronze
      </div>
      <div class="grid grid-cols-1 gap-y-4 items-center justify-items-center">
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-mille.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-domino.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
      </div>
    
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[15%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        前期活動A級
      </div>
      <div class="grid grid-cols-1 gap-y-2 items-center justify-items-center">
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-AlphaCamp.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[22%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        前期活動B級
      </div>
      <div class="grid grid-cols-1 gap-y-2 gap-x-10 items-center justify-items-center">
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-SnowFactory.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-MaybeHomestay.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
          <div class="flex justify-center items-center col-span-2">
            <object
              data="/home/logo-Tlife.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[31%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        前期活動C級
      </div>
      <div class="grid grid-cols-2 gap-y-2 gap-x-4 items-center justify-items-center">
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-three.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-eatwithgo.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
      </div>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[37.8%] left-[50%] translate-x-[-50%]"
    >
      媒體合作
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[40%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        Diamond
      </div>
      <div class="grid grid-cols-1 gap-y-4 items-center justify-items-center">
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-DailyView.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-TaiwanNews.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[52%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        Platinum
      </div>
      <div class="grid grid-cols-1 gap-y-2 items-center justify-items-center">
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-ETtoday.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 gap-y-4 items-center justify-items-center absolute top-[59%] left-[50%] translate-x-[-50%] w-[40%]"
    >
      <div
        class="font-Mantou text-[#43473E] text-[10px] font-medium"
      >
        共同發聲
      </div>
      <div class="grid grid-cols-1 gap-y-6 items-center justify-items-center">
        <div class="flex justify-center items-center">
            <object
              data="/home/logo-TiEA.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
          <div class="flex justify-center items-center">
            <object
              data="/home/logo-TCF.svg"
              type="image/svg+xml"
              class="h-10"
            ></object>
          </div>
      </div>
    </div>

    <object
      data="/home/icon-ve99.svg"
      type="image/svg+xml"
      class="absolute top-[9%] left-[52%]"
    ></object>
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[70%] left-[50%] translate-x-[-50%]"
    >
      協辦單位
    </div>
    <div
      class="absolute font-Inter space-y-4 text-[10px] font-normal text-center top-[74%] left-[50%] translate-x-[-50%] whitespace-nowrap"
    >
      <div class="flex justify-center items-center">
        <object
          data="/home/logo-ITSA.svg"
          type="image/svg+xml"
          class="h-10"
        ></object>
      </div>
      <div>國立陽明交通大學資訊工程學系</div>
      <div class="flex justify-center items-center">
        <object
          data="/home/logo-NCTU.svg"
          type="image/svg+xml"
          class="h-10"
        ></object>
      </div>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[86%] left-[50%] translate-x-[-50%]"
    >
      特別感謝
    </div>
    <div
      class="absolute space-y-2 font-Inter text-[10px] font-normal text-center top-[89%] left-[50%] translate-x-[-50%] whitespace-nowrap"
    >
      <div>國立陽明交通大學產學運籌中心</div>
      <div>國立陽明交通大學電機工程學系</div>
      <div>國立清華大學資訊工程學系</div>
      <div>國立清華大學電機資訊學院學士班</div>
      <div>國立清華大學清華學院學士班系學會</div>
      <div>國立清華大學 26 級經濟系學會</div>
      <div>Colde Garage</div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.sponsor-co8 {
  animation: animateCo8 4s ease-out infinite;
}

.sponsor-co16 {
  animation: animateCo16 8s ease-out infinite;
}

.sponsor-co11 {
  animation: animateCo11 3s ease-out infinite;
}

.sponsor-co14 {
  animation: animateCo14 4s ease-out infinite;
}

.sponsor-mo5 {
  animation: animateMo5 5s ease-out infinite;
}

.sponsor-co13 {
  animation: animateCo13 3s ease-out infinite;
}

@keyframes animateCo8 {
  0% {
    transform: translate(0, 0) rotate(160deg);
  }
  25% {
    transform: translate(-40px, 10px) rotate(160deg) scale(1.1);
  }
  50% {
    transform: translate(-40px, 10px) rotate(160deg) scale(1.1);
  }
  75% {
    transform: translate(0, 0) rotate(160deg);
  }
  100% {
    transform: translate(0, 0) rotate(160deg);
  }
}

@keyframes animateCo16 {
  0% {
    transform: rotate(59deg);
  }
  25% {
    transform: rotate(89deg);
  }
  50% {
    transform: rotate(89deg);
  }
  75% {
    transform: rotate(59deg);
  }
  100% {
    transform: rotate(59deg);
  }
}

@keyframes animateCo11 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -32px);
  }
  50% {
    transform: translate(0, -32px);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes animateCo14 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(40px, 10px) scale(1.1);
  }
  50% {
    transform: translate(40px, 10px) scale(1.1);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes animateMo5 {
  0% {
    transform: rotate(0);
  }
  12.5% {
    transform: rotate(16.92deg);
  }
  25% {
    transform: rotate(16.92deg);
  }
  37.5% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(0);
  }
  62.5% {
    transform: rotate(-19.92deg);
  }
  75% {
    transform: rotate(-19.92deg);
  }
  87.5% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes animateCo13 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -32px);
  }
  50% {
    transform: translate(0, -32px);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.sponsor-co53 {
  animation: animateCo53 2.5s ease-out infinite;
}

@keyframes animateCo53 {
  0% {
    transform: rotate(-4deg);
  }
  12.5% {
    transform: rotate(-20.92deg);
  }
  50% {
    transform: rotate(-20.92deg);
  }
  62.5% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
</style>
