<!-- 2 照片 - 介紹 @userwei -->

<template>
  <div
    class="hidden md:block relative h-[1300px] w-[full] overflow-hidden z-20 box-border"
  >
    <object
      data="/home/icon-ve31.svg"
      type="image/svg+xml"
      class="absolute top-[27%] left-[-4%]"
    ></object>
    <object
      data="/home/icon-ve32.svg"
      type="image/svg+xml"
      class="absolute top-[25%] left-[81%]"
    ></object>
    <object
      data="/home/icon-ve33.svg"
      type="image/svg+xml"
      class="absolute top-[73%] left-[14%]"
    ></object>
    <object
      data="/home/icon-mo03.svg"
      type="image/svg+xml"
      class="desc-mo3 absolute top-[85%] left-[5.5%]"
    ></object>
    <object
      data="/home/icon-co08.svg"
      type="image/svg+xml"
      class="desc-co8 absolute top-[58%] left-[0.1%]"
    ></object>

    <object
      data="/home/icon-co24.svg"
      type="image/svg+xml"
      class="desc-co24 absolute top-[61%] left-[78%]"
    ></object>
    <!-- <object
      data="/home/icon-re29.svg"
      type="image/svg+xml"
      class="absolute top-[7.6%] left-[50%] translate-x-[-50%]"
    ></object>
    <object
      data="/home/icon-re36.svg"
      type="image/svg+xml"
      class="absolute top-[6%] left-[50%] translate-x-[-50%]"
    ></object> -->
    <div class="absolute top-[7%]">
      <div class="flex flex-col items-center">
        <object
          data="/home/desc-1.png"
          type="image/png"
          class="w-[50%] border-[1px] p-4 border-[#43483E]"
          data-testid="icon-re239-hackers"
        ></object>
        <div
          class="w-[47%] font-[14px] font-Inter mt-10"
          data-testid="description-text"
        >
          科技的日新月異為我們帶來無限可能，而創意與想法更能跨越時空、引領未來。<br /><br />
          「2024 新竹 X
          梅竹黑客松」提供了一個近乎無限制的平台，無論你來自何處，都能組隊挑戰六間優秀企業與新竹市政府所提出的難題。在眾多合作單位的資源與技術支持下，參賽者們可以盡情將想法化為現實，打造屬於自己的「最小可行性產品」。<br /><br />
          梅竹黑客松，一場創意嘉年華，聚集來自各地、各專業、各背景的參賽者們，彼此激盪全新的想像力，共同創造出獨特而非凡的作品。在這場盛會裡，參賽者、政府、企業、以及黑客松團隊都是不可或缺的一部分。我們鼓勵來自不同領域的隊伍組成，從多元角度思考問題，讓提案或產品更加全面與具體，提升實現的可能性。<br /><br />
          今年，梅竹黑客松將在陽明交通大學體育館重返兩天一夜、不間斷的挑戰模式。參賽者們將發揮「黑客精神」，全力解決眼前的挑戰，而梅竹黑客松團隊也致力於提供更佳的比賽體驗，使每位參賽者不受時間與空間的限制，貫徹自己的創意，並將更完整的規劃與成果呈現給評審與大眾。<br /><br />
          「2024 新竹 X 梅竹黑客松」期許你的創意，能引領世界潮流走向無限。
        </div>
      </div>
    </div>

    <object
      data="/home/icon-ve29.svg"
      type="image/svg+xml"
      class="absolute top-[0.2%] left-[16%]"
    ></object>
    <object
      data="/home/icon-co16.svg"
      type="image/svg+xml"
      class="desc-co16 absolute top-[1.5%] left-[30%]"
    ></object>
  </div>
  <div
    class="block md:hidden relative h-[1200px] w-[full] overflow-hidden z-20 box-border"
  >
    <div class="absolute top-[73%] left-[50%] translate-x-[-50%]">
      <object
        data="/home/icon-gr11.svg"
        type="image/svg+xml"
        class="desc-co2"
      ></object>
    </div>
    <object
      data="/home/icon-gr80.svg"
      type="image/svg+xml"
      class="desc-gr80 absolute top-[91%] left-[75%]"
    ></object>
    <!-- <object
      data="/home/icon-re29-2.svg"
      type="image/svg+xml"
      class="absolute top-[7.8%] left-[50%] translate-x-[-50%]"
    ></object>
    <object
      data="/home/icon-re36-2.svg"
      type="image/svg+xml"
      class="absolute top-[7%] left-[50%] translate-x-[-50%]"
    ></object> -->
    <div class="absolute top-[4%]">
      <div class="flex flex-col items-center">
        <object
          data="/home/desc-1.png"
          type="image/png"
          class="w-[75%] border-[0.5px] p-2 border-[#43483E]"
          data-testid="icon-re239-hackers"
        ></object>
        <div
          class="w-[70%] text-[12px] font-Inter mt-6"
          data-testid="description-text-md"
        >
          科技的日新月異為我們帶來無限可能，而創意與想法更能跨越時空、引領未來。<br /><br />
          「2024 新竹 X
          梅竹黑客松」提供了一個近乎無限制的平台，無論你來自何處，都能組隊挑戰六間優秀企業與新竹市政府所提出的難題。在眾多合作單位的資源與技術支持下，參賽者們可以盡情將想法化為現實，打造屬於自己的「最小可行性產品」。<br /><br />
          梅竹黑客松，一場創意嘉年華，聚集來自各地、各專業、各背景的參賽者們，彼此激盪全新的想像力，共同創造出獨特而非凡的作品。在這場盛會裡，參賽者、政府、企業、以及黑客松團隊都是不可或缺的一部分。我們鼓勵來自不同領域的隊伍組成，從多元角度思考問題，讓提案或產品更加全面與具體，提升實現的可能性。<br /><br />
          今年，梅竹黑客松將在陽明交通大學體育館重返兩天一夜、不間斷的挑戰模式。參賽者們將發揮「黑客精神」，全力解決眼前的挑戰，而梅竹黑客松團隊也致力於提供更佳的比賽體驗，使每位參賽者不受時間與空間的限制，貫徹自己的創意，並將更完整的規劃與成果呈現給評審與大眾。<br /><br />
          「2024 新竹 X 梅竹黑客松」期許你的創意，能引領世界潮流走向無限。
        </div>
      </div>
    </div>
    <object
      data="/home/icon-ve29-2.svg"
      type="image/svg+xml"
      class="absolute top-[1.2%] left-[8%]"
    ></object>
    <object
      data="/home/icon-ve31-2.svg"
      type="image/svg+xml"
      class="absolute top-[54%] left-[0%]"
    ></object>
    <object
      data="/home/icon-ve26-2.svg"
      type="image/svg+xml"
      class="absolute top-[39%] left-[63%]"
    ></object>
    <object
      data="/home/icon-ve82-2.svg"
      type="image/svg+xml"
      class="absolute top-[51%] left-[90%]"
    ></object>
    <object
      data="/home/icon-ve83-2.svg"
      type="image/svg+xml"
      class="absolute top-[85%] left-[60%]"
    ></object>
  </div>
</template>

<script setup></script>

<style scoped>
.desc-co2 {
  animation: animateCo2 3s ease-out infinite;
}

@keyframes animateCo2 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -32px);
  }
  50% {
    transform: translate(0, -32px);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.desc-co8 {
  animation: animateCo8 4s ease-out infinite;
}

@keyframes animateCo8 {
  0% {
    transform: translate(0, 0) rotate(160deg);
  }
  25% {
    transform: translate(-40px, 10px) rotate(160deg) scale(1.1);
  }
  50% {
    transform: translate(-40px, 10px) rotate(160deg) scale(1.1);
  }
  75% {
    transform: translate(0, 0) rotate(160deg);
  }
  100% {
    transform: translate(0, 0) rotate(160deg);
  }
}
.desc-co16 {
  animation: animateCo16 3.5s ease-out infinite;
}

@keyframes animateCo16 {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(30deg);
  }
  75% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.desc-co24 {
  animation: animateCo24 3s ease-out infinite;
}

@keyframes animateCo24 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -32px);
  }
  50% {
    transform: translate(0, -32px);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.desc-gr80 {
  animation: animateGr80 2.5s ease-out infinite;
}

@keyframes animateGr80 {
  0% {
    transform: rotate(-4deg);
  }
  12.5% {
    transform: rotate(-20.92deg);
  }
  50% {
    transform: rotate(-20.92deg);
  }
  62.5% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
</style>
