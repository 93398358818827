<!-- 8 工作人員名單 @lynu369 -->

<template>
  <div
    class="hidden md:block relative h-[900px] w-[full] overflow-hidden z-20 box-border"
  >
    <div
      class="font-Mantou text-[#43473E] text-[36px] text-[400] absolute top-[0%] left-[50%] translate-x-[-50%]"
    >
      工作人員名單
    </div>
    <object
      data="/home/icon-ve49.svg"
      type="image/svg+xml"
      class="absolute top-[13%] left-[8.5%] w-[10%]"
    ></object>
    <object
      data="/home/icon-gr53.svg"
      type="image/svg+xml"
      class="staff-gr53-1 absolute top-[16%] left-[6%] w-[10%]"
    ></object>
    <div
      class="font-Mantou text-[#43473E] text-[30px] text-[400] absolute top-[20.5%] left-[15.5%] translate-x-[-50%]"
    >
      行政部
    </div>
    <div class="absolute font-Inter text-[16px] text-[500] font-normal top-[22.5%] left-[22%]">
      <div>陳玟綺 / 施閔智</div>
    </div>
    <object
      data="/home/icon-ve49.svg"
      type="image/svg+xml"
      class="absolute top-[13%] left-[51%] w-[10%]"
    ></object>
    <object
      data="/home/icon-gr53.svg"
      type="image/svg+xml"
      class="staff-gr53-2 absolute top-[16%] left-[48.5%] w-[10%]"
    ></object>
    <div
      class="font-Mantou text-[#43473E] text-[30px] text-[400] absolute top-[20.5%] left-[58%] translate-x-[-50%]"
    >
      活動部
    </div>
    <div class="absolute font-Inter text-[16px] text-[500] font-normal top-[22.5%] left-[64.5%] max-w-[30%]">
      <div>廖苡安 / 蔡沂霖 / 游子鋆</div>
      <div>陳芃嬡 / 林沛含 / 施又歆 / 蕭宇程 / 方以佳 / 蘇恩誼 / 范嘉尹</div>
    </div>

    <object
      data="/home/icon-ve49.svg"
      type="image/svg+xml"
      class="absolute top-[33%] left-[8.5%] w-[10%]"
    ></object>
    <object
      data="/home/icon-gr53.svg"
      type="image/svg+xml"
      class="staff-gr53-1 absolute top-[36%] left-[6%] w-[10%]"
    ></object>
    <div
      class="font-Mantou text-[#43473E] text-[30px] text-[400] absolute top-[40.5%] left-[15.5%] translate-x-[-50%]"
    >
      行銷部
    </div>
    <div class="absolute font-Inter text-[16px] text-[500] font-normal max-w-[23%] top-[42.5%] left-[22%]">
      <div>黃聖溫 / 駱櫻文 / 李芷薰</div>
      <div>唐品芳 / 陳宣蓉 / 鄭宥呈 / 劉士嫙 / 許閔閔 / 蔡修奇</div>
    </div>
    <object
      data="/home/icon-ve49.svg"
      type="image/svg+xml"
      class="absolute top-[33%] left-[51%] w-[10%]"
    ></object>
    <object
      data="/home/icon-gr53.svg"
      type="image/svg+xml"
      class="staff-gr53-2 absolute top-[36%] left-[48.5%] w-[10%]"
    ></object>
    <div
      class="font-Mantou text-[#43473E] text-[30px] text-[400] absolute top-[40.5%] left-[58%] translate-x-[-50%]"
    >
      財務部
    </div>
    <div class="absolute font-Inter text-[16px] text-[500] font-normal top-[42.5%] left-[64.5%] max-w-[30%]">
      <div>吳念祖 / 劉宣亭</div>
      <div>黃郁翔 / 李采穗 / 江安 / 陳姿穎 / 李品萱</div>
    </div>
    <object
      data="/home/icon-ve49.svg"
      type="image/svg+xml"
      class="absolute top-[53%] left-[8.5%] w-[10%]"
    ></object>
    <object
      data="/home/icon-gr53.svg"
      type="image/svg+xml"
      class="staff-gr53-1 absolute top-[56%] left-[6%] w-[10%]"
    ></object>
    <div
      class="font-Mantou text-[#43473E] text-[30px] text-[400] absolute top-[60.5%] left-[15.5%] translate-x-[-50%]"
    >
      公關部
    </div>
    <div class="absolute font-Inter text-[16px] text-[500] font-normal max-w-[23%] top-[62.5%] left-[22%]">
      <div>蕭令宜 / 郭伊真 / 鄒宜儒</div>
      <div>蔡婕安 / 賴子睿 / 侯佑勳 / 莊皓雯 / 呂宜珊 / 陳香均</div>
    </div>

    <object
      data="/home/icon-ve49.svg"
      type="image/svg+xml"
      class="absolute top-[53%] left-[51%] w-[10%]"
    ></object>
    <object
      data="/home/icon-gr53.svg"
      type="image/svg+xml"
      class="staff-gr53-2 absolute top-[56%] left-[48.5%] w-[10%]"
    ></object>
    <div
      class="font-Mantou text-[#43473E] text-[30px] text-[400] absolute top-[60.5%] left-[58%] translate-x-[-50%]"
    >
      設計部
    </div>
    <div class="absolute font-Inter text-[16px] text-[500] font-normal top-[62.5%] left-[64.5%] max-w-[30%]">
      <div>黃壬煜 / 陳冠宇</div>
      <div>陳婷祺 / 黎姵妤 / 葉惠棻 / 陳又寧 / 陳薇安 / 黃詠淳</div>
    </div>
    <object
      data="/home/icon-ve49.svg"
      type="image/svg+xml"
      class="absolute top-[73%] left-[51%] w-[10%]"
    ></object>
    <object
      data="/home/icon-gr53.svg"
      type="image/svg+xml"
      class="staff-gr53-2 absolute top-[76%] left-[48.5%] w-[10%]"
    ></object>
    <div
      class="font-Mantou text-[#43473E] text-[30px] text-[400] absolute top-[80.5%] left-[58%] translate-x-[-50%]"
    >
      開發部
    </div>
    <div class="absolute font-Inter text-[16px] text-[500] font-normal top-[82.5%] left-[64.5%] max-w-[30%]">
      <div>朱驛庭 / 黃佑得 / 何義翔</div>
      <div>周廷威 / 曾士珍 / 林嘉宏 / 陳俐妤 / 李昀祐 / 陳芝瑄</div>
    </div>
  </div>
  <div
    class="block md:hidden relative h-[560px] w-[full] overflow-hidden z-20 box-border"
  >
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[0%] left-[50%] translate-x-[-50%]"
    >
      工作人員名單
    </div>
    <div
        class="font-Mantou text-[#43473E] text-[12px] text-[400] absolute top-[10%] left-[15.5%] translate-x-[-50%]"
      >
        行政部
      </div>
      <div class="absolute font-Inter text-[10px] text-[500] font-normal top-[10%] left-[25%]">
        <div>陳玟綺 / 施閔智</div>
      </div>
    <div
      class="font-Mantou text-[#43473E] text-[12px] text-[400] absolute top-[16%] left-[15.5%] translate-x-[-50%]"
    >
      活動部
    </div>
    <div class="absolute font-Inter text-[10px] text-[500] font-normal top-[16%] left-[25%] max-w-[70%]">
      <div>廖苡安 / 蔡沂霖 / 游子鋆</div>
      <div>陳芃嬡 / 林沛含 / 施又歆 / 蕭宇程 / 方以佳 / 蘇恩誼 / 范嘉尹</div>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[12px] text-[400] absolute top-[24%] left-[15.5%] translate-x-[-50%]"
    >
      行銷部
    </div>
    <div class="absolute font-Inter text-[10px] text-[500] font-normal top-[24%] left-[25%] max-w-[70%]">
      <div>黃聖溫 / 駱櫻文 / 李芷薰</div>
      <div>唐品芳 / 陳宣蓉 / 鄭宥呈 / 劉士嫙 / 許閔閔 / 蔡修奇</div>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[12px] text-[400] absolute top-[32%] left-[15.5%] translate-x-[-50%]"
    >
      財務部
    </div>
    <div class="absolute font-Inter text-[10px] text-[500] font-normal top-[32%] left-[25%] max-w-[70%]">
      <div>吳念祖 / 劉宣亭</div>
      <div>黃郁翔 / 李采穗 / 江安 / 陳姿穎 / 李品萱</div>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[12px] text-[400] absolute top-[40%] left-[15.5%] translate-x-[-50%]"
    >
      公關部
    </div>
    <div class="absolute font-Inter text-[10px] text-[500] font-normal top-[40%] left-[25%] max-w-[70%]">
      <div>蕭令宜 / 郭伊真 / 鄒宜儒</div>
      <div>蔡婕安 / 賴子睿 / 侯佑勳 / 莊皓雯 / 呂宜珊 / 陳香均</div>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[12px] text-[400] absolute top-[48%] left-[15.5%] translate-x-[-50%]"
    >
      設計部
    </div>
    <div class="absolute font-Inter text-[10px] text-[500] font-normal top-[48%] left-[25%] max-w-[70%]">
      <div>黃壬煜 / 陳冠宇</div>
      <div>陳婷祺 / 黎姵妤 / 葉惠棻 / 陳又寧 / 陳薇安 / 黃詠淳</div>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[12px] text-[400] absolute top-[56%] left-[15.5%] translate-x-[-50%]"
    >
      開發部
    </div>
    <div class="absolute font-Inter text-[10px] text-[500] font-normal top-[56%] left-[25%] max-w-[70%]">
      <div>朱驛庭 / 黃佑得 / 何義翔</div>
      <div>周廷威 / 曾士珍 / 林嘉宏 / 陳俐妤 / 李昀祐 / 陳芝瑄</div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.staff-gr53-1 {
  animation: animateGr53-1 5s ease-out infinite;
}

.staff-gr53-2 {
  animation: animateGr53-2 5s ease-out infinite;
}

@keyframes animateGr53-1 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -8px);
  }
  50% {
    transform: translate(0, -8px);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes animateGr53-2 {
  0% {
    transform: translate(0, -8px);
  }
  25% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(0, -8px);
  }
  100% {
    transform: translate(0, -8px);
  }
}
</style>
