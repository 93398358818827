<template>
    <!-- <div class="hidden md:flex lg:max-h-[100px] w-full h-[15vh] justify-between items-center mt-[3%]">
        
        <object data="Company\top_left.svg"
        type="image/svg+xml"
        class="flex max-w-[250px] w-[20vw] aspect-w-249 aspect-h-78 flex-shrink-0"></object>
        <div class="flex w-full h-full pl-[2vw] pr-[2vw] pt-[.5vw] pb-[.5vw] justify-between items-center">
            <object class="relative border-dark-gray flex-grow h-[45%] mx-auto flex-shrink-0 rounded-[25px] border-[3px] border-dashed"></object>
        </div>
        <object data="Company\top_right.svg"
        type="image/svg+xml"
        class="flex max-w-[250px] w-[20vw] aspect-w-249 aspect-h-78 flex-shrink-0"></object>
    </div> -->
    <object data="Company\top.svg" type="image/svg+xml" class="w-full mb-2 mt-2"></object>
</template>
      
<script>
export default {

}
</script>
    
<style>
    
</style>