<!-- 4 獎項資訊 @userwei -->

<template>
  <div
    class="hidden md:block relative h-[1000px] w-[full] overflow-hidden z-20 box-border"
  >
    <div
      class="font-Mantou text-[#43473E] text-[36px] text-[400] absolute top-[7%] left-[50%] translate-x-[-50%]"
    >
      獎項資訊
    </div>
    <object
      data="/home/icon-ve72.svg"
      type="image/svg+xml"
      class="absolute top-[7%] left-[2%]"
    ></object>
    <object
      data="/home/icon-ve73.svg"
      type="image/svg+xml"
      class="absolute top-[0.1%] left-[77%]"
    ></object>
    <object
      data="/home/icon-ve74.svg"
      type="image/svg+xml"
      class="absolute top-[58%] left-[19.5%]"
    ></object>
    <object
      data="/home/icon-ve75.svg"
      type="image/svg+xml"
      class="absolute top-[26%] left-[85%]"
    ></object>
    <object
      data="/home/icon-co31.svg"
      type="image/svg+xml"
      class="prize-co31 absolute top-[67%] left-[77%]"
    ></object>
    <object
      data="/home/icon-co28.svg"
      type="image/svg+xml"
      class="prize-co28 absolute top-[65%] left-[27%]"
    ></object>
    <object
      data="/home/icon-co29.svg"
      type="image/svg+xml"
      class="prize-co29 absolute top-[70%] left-[24%]"
    ></object>
    <object
      data="/home/icon-co30.svg"
      type="image/svg+xml"
      class="prize-co30 absolute top-[75%] left-[21%]"
    ></object>
    <div
      class="grid grid-cols-2 gap-y-6 absolute top-[20%] left-[50%] translate-x-[-50%]"
    >
      <div
        class="col-span-2 border-[#D9D9D9] border-[3px] rounded-[40px] px-10 py-6 bg-white hover:bg-[#FEFFF0] hover:border-[#D8E022]"
      >
        <div class="flex flex-col gap-4">
          <div class="flex flex-row gap-4 items-baseline">
            <div class="font-Mantou text-[#43473E] text-[34px] text-[400]">
              黑客組
            </div>
            <div class="font-Inter text-[12px] text-[400]">
              每間企業獨立評選
            </div>
          </div>
          <div class="flex flex-col gap-1 font-Inter text-[16px] text-[400]">
            <div>
              第一名：新台幣 20,000 元整、企業實體獎品、實習或實習面試機會
            </div>
            <div>第二名：新台幣 15,000 元整、企業實體獎品</div>
            <div>第三名：新台幣 10,000 元整、企業實體獎品</div>
            <div class="pt-[2px] text-[12px]">*實習機會主要依據企業本身而定</div>
          </div>
        </div>
      </div>
      <div
        class="col-span-2 border-[#D9D9D9] border-[3px] rounded-[40px] px-10 py-6 bg-white hover:bg-[#FEFFF0] hover:border-[#D8E022]"
      >
        <div class="flex flex-col gap-4">
          <div class="font-Mantou text-[#43473E] text-[34px] text-[400]">
            創客交流組
          </div>
          <div class="grid grid-cols-2">
            <div class="flex flex-col gap-1 font-Inter text-[16px] text-[400]">
              <div>第一名：新台幣 50,000 元整</div>
              <div>第二名：新台幣 40,000 元整</div>
              <div>第三名：新台幣 30,000 元整</div>
            </div>
            <div class="flex flex-col gap-1 font-Inter text-[16px] text-[400]">
              <div>創意獎一：新台幣 12,000 元整</div>
              <div>創意獎二：新台幣 10,000 元整</div>
              <div>創意獎三：新台幣 8,000 元整</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-start-2 border-[#D9D9D9] border-[3px] rounded-[40px] px-10 py-6 bg-white hover:bg-[#FEFFF0] hover:border-[#D8E022]"
      >
        <div class="flex flex-col gap-4">
          <div class="flex flex-col">
            <div class="font-Mantou text-[#43473E] text-[34px] text-[400]">
              梅竹大獎
            </div>
            <div class="font-Inter text-[12px] text-[400]">
              由各企業第一名共同角逐
            </div>
          </div>
          <div class="flex flex-col gap-1 font-Inter text-[16px] text-[400]">
            <div>第一名：新台幣 28,000 元整</div>
            <div>第二名：新台幣 16,000 元整</div>
            <div>第三名：新台幣 8,000 元整</div>
          </div>
        </div>
      </div>
    </div>
    <object
      data="/home/icon-mo08.svg"
      type="image/svg+xml"
      class="prize-mo8 absolute top-[33%] left-[63%]"
    ></object>
  </div>
  <div
    class="block md:hidden relative h-[1100px] w-[full] overflow-hidden z-20 box-border"
  >
    <div class="absolute top-[70%] left-[50%] translate-x-[-50%]">
      <object
        data="/home/icon-gr11.svg"
        type="image/svg+xml"
        class="prize-gr11"
      ></object>
    </div>
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[13%] left-[50%] translate-x-[-50%]"
    >
      獎項資訊
    </div>
    <object
      data="/home/icon-ve106.svg"
      type="image/svg+xml"
      class="absolute top-[0%] left-[0%]"
    ></object>
    <div
      class="grid grid-cols-2 gap-y-4 absolute top-[18.5%] left-[50%] translate-x-[-50%] w-[85dvw]"
    >
      <div
        class="col-span-2 border-[#D9D9D9] border-[3px] rounded-[20px] p-3 bg-white hover:bg-[#FEFFF0] hover:border-[#D8E022]"
      >
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-2 items-baseline">
            <div class="font-Mantou text-[#43473E] text-[24px] text-[400]">
              黑客組
            </div>
            <div class="font-Inter text-[8px] text-[400]">每間企業獨立評選</div>
          </div>
          <div class="flex flex-col gap-1 font-Inter text-[10px] text-[400]">
            <div>
              第一名：新台幣 20,000 元整、企業實體獎品、實習或實習面試機會
            </div>
            <div>第二名：新台幣 15,000 元整、企業實體獎品</div>
            <div>第三名：新台幣 10,000 元整、企業實體獎品</div>
            <div class="text-[8px]">*實習機會主要依據企業本身而定</div>
          </div>
        </div>
      </div>
      <div
        class="col-span-2 border-[#D9D9D9] border-[3px] rounded-[20px] p-3 bg-white hover:bg-[#FEFFF0] hover:border-[#D8E022]"
      >
        <div class="flex flex-col gap-2">
          <div class="font-Mantou text-[#43473E] text-[24px] text-[400]">
            創客交流組
          </div>
          <div class="grid grid-cols-2">
            <div class="flex flex-col gap-1 font-Inter text-[10px] text-[400]">
              <div>第一名：新台幣 50,000 元整</div>
              <div>第二名：新台幣 40,000 元整</div>
              <div>第三名：新台幣 30,000 元整</div>
            </div>
            <div class="flex flex-col gap-1 font-Inter text-[10px] text-[400]">
              <div>創意獎一：新台幣 12,000 元整</div>
              <div>創意獎二：新台幣 10,000 元整</div>
              <div>創意獎三：新台幣 8,000 元整</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center gap-3">
        <object
          data="/home/icon-gr22.svg"
          type="image/svg+xml"
          class="prize-gr22"
        ></object>
        <object
          data="/home/icon-gr23.svg"
          type="image/svg+xml"
          class="prize-gr23"
        ></object>
        <object
          data="/home/icon-gr24.svg"
          type="image/svg+xml"
          class="prize-gr24"
        ></object>
      </div>
      <div
        class="border-[#D9D9D9] border-[3px] rounded-[20px] p-3 bg-white hover:bg-[#FEFFF0] hover:border-[#D8E022]"
      >
        <div class="flex flex-col gap-2">
          <div class="flex flex-col">
            <div class="font-Mantou text-[#43473E] text-[24px] text-[400]">
              梅竹大獎
            </div>
            <div class="font-Inter text-[8px] text-[400]">
              由各企業第一名共同角逐
            </div>
          </div>
          <div class="flex flex-col gap-1 font-Inter text-[10px] text-[400]">
            <div>第一名：新台幣 28,000 元整</div>
            <div>第二名：新台幣 16,000 元整</div>
            <div>第三名：新台幣 8,000 元整</div>
          </div>
        </div>
      </div>
    </div>

    <object
      data="/home/icon-ve88-2.svg"
      type="image/svg+xml"
      class="absolute top-[57%] left-[62%]"
    ></object>
    <object
      data="/home/icon-ve89.svg"
      type="image/svg+xml"
      class="absolute top-[69%] left-[89%]"
    ></object>
    <object
      data="/home/icon-ve90.svg"
      type="image/svg+xml"
      class="absolute top-[74%] left-[0%]"
    ></object>
    <object
      data="/home/icon-gr80.svg"
      type="image/svg+xml"
      class="prize-gr80 absolute top-[28%] left-[73%]"
    ></object>
    <object
      data="/home/icon-gr26.svg"
      type="image/svg+xml"
      class="prize-gr26 absolute top-[4%] left-[8%]"
    ></object>
  </div>
</template>

<script setup></script>

<style scoped>
.prize-gr11 {
  animation: animateGr11 3s ease-out infinite;
}

@keyframes animateGr11 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -32px);
  }
  50% {
    transform: translate(0, -32px);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.prize-mo8 {
  animation: animateMo8 5s ease-out infinite;
}

@keyframes animateMo8 {
  0% {
    transform: rotate(12deg);
  }
  12.5% {
    transform: rotate(28.92deg);
  }
  25% {
    transform: rotate(28.92deg);
  }
  37.5% {
    transform: rotate(42.92deg);
  }
  50% {
    transform: rotate(42.92deg);
  }
  62.5% {
    transform: rotate(28.92deg);
  }
  75% {
    transform: rotate(28.92deg);
  }
  87.5% {
    transform: rotate(12deg);
  }
  100% {
    transform: rotate(4deg);
  }
}

.prize-co31 {
  animation: animateCo31 3s ease-out infinite;
}

@keyframes animateCo31 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -32px);
  }
  50% {
    transform: translate(0, -32px);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.prize-co28 {
  animation: animateCo28 4s ease-out infinite;
}

@keyframes animateCo28 {
  0% {
    transform: translate(0, 0) rotate(180deg);
  }
  25% {
    transform: translate(-40px, 0px) rotate(180deg) scale(1.1);
  }
  50% {
    transform: translate(-40px, 0px) rotate(180deg) scale(1.1);
  }
  75% {
    transform: translate(0, 0) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
}

.prize-co29 {
  animation: animateCo29 4s ease-out 0.1s infinite;
}

@keyframes animateCo29 {
  0% {
    transform: translate(0, 0) rotate(180deg);
  }
  25% {
    transform: translate(-40px, 0px) rotate(180deg) scale(1.1);
  }
  50% {
    transform: translate(-40px, 0px) rotate(180deg) scale(1.1);
  }
  75% {
    transform: translate(0, 0) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
}

.prize-co30 {
  animation: animateCo30 4s ease-out 0.2s infinite;
}

@keyframes animateCo30 {
  0% {
    transform: translate(0, 0) rotate(180deg);
  }
  25% {
    transform: translate(-40px, 0px) rotate(180deg) scale(1.1);
  }
  50% {
    transform: translate(-40px, 0px) rotate(180deg) scale(1.1);
  }
  75% {
    transform: translate(0, 0) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
}

.prize-gr22 {
  animation: animateGr22 4s ease-out infinite;
}

@keyframes animateGr22 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-20px, 0px) scale(1.1);
  }
  50% {
    transform: translate(-20px, 0px) scale(1.1);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.prize-gr23 {
  animation: animateGr23 4s ease-out infinite;
}

@keyframes animateGr23 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-20px, 0px) scale(1.1);
  }
  50% {
    transform: translate(-20px, 0px) scale(1.1);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.prize-gr24 {
  animation: animateGr24 4s ease-out infinite;
}

@keyframes animateGr24 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-20px, 0px) scale(1.1);
  }
  50% {
    transform: translate(-20px, 0px) scale(1.1);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.prize-gr26 {
  animation: animateGr26 3s ease-out infinite;
}

@keyframes animateGr26 {
  0% {
    transform: rotate(-4deg);
  }
  12.5% {
    transform: rotate(-20.92deg);
  }
  50% {
    transform: rotate(-20.92deg);
  }
  62.5% {
    transform: rotate(-10deg);
  }
  87.5% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}

.prize-gr80 {
  animation: animateGr80 2.5s ease-out infinite;
}

@keyframes animateGr80 {
  0% {
    transform: rotate(-4deg);
  }
  12.5% {
    transform: rotate(-20.92deg);
  }
  50% {
    transform: rotate(-20.92deg);
  }
  62.5% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
</style>
